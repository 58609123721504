import {
  denormalizeQuickReplyResponse,
  QuickReplyNormalizedType,
  QUICK_REPLY_ENTRIES_INCREASED_LIMIT,
  QUICK_REPLY_ENTRIES_LIMIT,
} from "../../types/QuickReplies/QuickReplyType";
import { get } from "../apiRequest";
import { GET_QUICK_REPLIES } from "../apiPath";
export function getQuickReplyLimit(companyId: string) {
  return process.env.REACT_APP_SAVED_LIMIT_INCREASE_COMPANY_ID === companyId
    ? QUICK_REPLY_ENTRIES_INCREASED_LIMIT
    : QUICK_REPLY_ENTRIES_LIMIT;
}
export async function fetchCompanyQuickReplies(
  language: string,
  companyId: string
) {
  const results: { list: QuickReplyNormalizedType[] } = await get(
    GET_QUICK_REPLIES,
    {
      param: {
        offset: 0,
        limit: getQuickReplyLimit(companyId),
      },
    }
  );
  return results.list.map((r) => {
    return denormalizeQuickReplyResponse(r, language);
  });
}
