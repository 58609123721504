import { useAuth0 } from "@auth0/auth0-react";
import { useQueryData } from "api/apiHook";
import { useAppSelector } from "AppRootContext";
import { StaffListType } from "component/Chat/types";
import { equals } from "ramda";
import { StaffType } from "types/StaffType";

type Request = {
  invite_users: {
    email: string;
    userRole: string;
  }[];
  location: string;
  team_ids: number[];
};
type Response = {
  data: StaffType[];
  date_time: string;
  message: string;
  http_status_code: number;
};
export default function useInviteUserByEmail({
  data,
  enabled,
}: {
  data: Request;
  enabled: boolean;
}) {
  return useQueryData<Response>(
    `/v1/tenant-hub/authorized/Companies/InviteUserByEmail`,
    {
      ...data,
    },
    {
      protocol: "post",
      enabled,
    }
  );
}
